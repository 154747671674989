
<template>
  <div class="bang bgcolor">
      <Head  :show="true" :bg="true" />
      <div class="bang-main">
          <img class="bang-bei" src="../../../assets/images/bang-bei.png" alt="">
          <h1 class="big-title">{{$t('mine.Honor_Roll')}}</h1>

          <div class="bang-box">
              <!-- <div class="bang-box-one" @click="showPopup"> 
                  <h2>神一般</h2>
                  <p>{{$t('mine.see')}}{{$t('mine.listboard')}} <van-icon name="arrow" ></van-icon></p>
              </div> -->
              <div class="bang-list">
                  <div class="bang-list-item" @click="showPopup($t('mine.max_bonus'),'mostBouns')"> 

                      <!-- 获得最多奖金 -->
                        <h3>{{$t('mine.max_bonus')}}</h3>
                        <p>{{$t('mine.see')}}{{$t('mine.listboard')}} <van-icon name="arrow" ></van-icon></p>
                  </div>
                  <div class="bang-list-item" @click="showPopup1($t('mine.max_level'),'mostRank')">
                      <!-- 最高等级排行 -->
                        <h3>{{$t('mine.max_level')}}</h3>
                        <p>{{$t('mine.see')}}{{$t('mine.listboard')}} <van-icon name="arrow" ></van-icon></p>
                  </div>
                  <div class="bang-list-item" @click="showPopup2($t('mine.win_amount'),'mostMoney')">
                      <!-- 中奖累计金额 -->
                        <h3>{{$t('mine.win_amount')}}</h3>
                        <p>{{$t('mine.see')}}{{$t('mine.listboard')}} <van-icon name="arrow" ></van-icon></p>
                  </div>
                  <div class="bang-list-item" @click="showPopup3($t('mine.max_comment'),'mostFacebook')">
                        <!-- 评论最多 -->
                        <h3>{{$t('mine.max_comment')}}</h3>
                        <p>{{$t('mine.see')}}{{$t('mine.listboard')}} <van-icon name="arrow" ></van-icon></p>
                  </div>
              </div>
          </div>
      </div>


    <van-popup class="bang-popup" v-model="show" position="bottom"  :style="{ height: '90%' }" duration="0.3">
        <div class="bang-popup-box">
            <div class="bang-popup-box-top">
                <p class="title">{{title}}</p>
                <van-icon @click="show=false" name="cross" class="closed" />
            </div>
            <div class="bang-popup-box-main">
                <div class="bang-item" v-for="(l,i) in mostBouns" :key="i">
                    <div class="bang-item-left">
                        <p class="left-num">
                            <span v-if="i>2">{{i}}</span>
                            <img v-show="i==0" src="../../../assets/images/first.png" alt="">
                            <img  v-show="i==1" src="../../../assets/images/two.png" alt="">
                            <img  v-show="i==2" src="../../../assets/images/three.png" alt="">
                        </p>
                        <img class="bang-tou" v-lazy="l.thumb" alt="">
                        <span>{{l.uid}}/{{l.nickname}}</span>
                    </div>
                    <div class="bang-item-right">
                        <!-- 累计获得奖金 -->
                        <span>{{$t('mine.total_bonus')}}</span>
                        <img class="pv-on" v-lazy="require('@/assets/images/pv-on.png')" alt="">
                        <span class="color-main">{{l.total_bonus | number_format}}</span>
                    </div>
                </div>
            </div>
        </div>
    </van-popup>

    <van-popup class="bang-popup" v-model="show1" position="bottom"  :style="{ height: '90%' }" duration="0.3">
        <div class="bang-popup-box">
            <div class="bang-popup-box-top">
                <p class="title">{{title}}</p>
                <van-icon @click="show1=false" name="cross" class="closed" />
            </div>
            <div class="bang-popup-box-main">
                    <el-table 
                        :data="mostRank"
                        class="bang-table"
                        v-if="formThead.length>0"
                    >
                        <af-table-column  :width="formThead[0].width+60">
                            <template slot-scope="scope" align="left">
                                <div class="bang-item-left">
                                    <p class="left-num" >
                                        <span v-if="scope.$index>2">{{scope.$index}}</span>
                                        <img v-show="scope.$index==0" src="../../../assets/images/first.png" alt="">
                                        <img  v-show="scope.$index==1" src="../../../assets/images/two.png" alt="">
                                        <img  v-show="scope.$index==2" src="../../../assets/images/three.png" alt="">
                                    </p>
                                    <img class="bang-tou" v-lazy="scope.row.thumb" alt="">
                                    <span class="p-table-name">{{scope.row.uid}}/{{scope.row.nickname}}</span>
                                </div>
                            </template>
                        </af-table-column>
                        <af-table-column align="center" :width="formThead[1].width">
                            <template slot-scope="scope">
                                <p class="p-rank-table">{{scope.row.rankname}}</p>
                            </template>
                        </af-table-column >
                        <af-table-column align="right" :width="formThead[2].width">
                            <template slot-scope="scope">
                                <span class="color-main p-table-span">{{scope.row.pvbalance | int_format}} PV</span>
                            </template>
                        </af-table-column >
                    </el-table>
                <!-- </div> -->
                
            </div>
        </div>
    </van-popup>


    <van-popup class="bang-popup" v-model="show2" position="bottom"  :style="{ height: '90%' }" duration="0.3">
        <div class="bang-popup-box">
            <div class="bang-popup-box-top">
                <p class="title">{{title}}</p>
                <van-icon @click="show2=false" name="cross" class="closed" />
            </div>
            <div class="bang-popup-box-main">
                <div class="bang-item" v-for="(l,i) in mostMoney" :key="i">
                    <div class="bang-item-left">
                        <p class="left-num">
                            <span v-if="i>2">{{i}}</span>
                            <img v-show="i==0" src="../../../assets/images/first.png" alt="">
                            <img  v-show="i==1" src="../../../assets/images/two.png" alt="">
                            <img  v-show="i==2" src="../../../assets/images/three.png" alt="">
                        </p>
                        <img class="bang-tou" v-lazy="l.thumb" alt="">
                        <span>{{l.uid}}/{{l.nickname}}</span>
                    </div>
                    <div class="bang-item-right">
                        <!-- 累计金额 -->
                        <span class="jifen">{{$t('mine.sum_recharge')}}</span>
                        <span class="color-main">${{l.total_bonus | number_format}}</span>
                    </div>
                </div>
            </div>
        </div>
    </van-popup>


    <van-popup class="bang-popup" v-model="show3" position="bottom"  :style="{ height: '90%' }" duration="0.3">
        <div class="bang-popup-box">
            <div class="bang-popup-box-top">
                <p class="title">{{title}}</p>
                <van-icon @click="show3=false" name="cross" class="closed" />
            </div>
            <div class="bang-popup-box-main">
                <div class="bang-item" v-for="(l,i) in mostFacebook" :key="i">
                    <div class="bang-item-left">
                        <p class="left-num">
                            <span v-if="i>2">{{i}}</span>
                            <img v-show="i==0" src="../../../assets/images/first.png" alt="">
                            <img  v-show="i==1" src="../../../assets/images/two.png" alt="">
                            <img  v-show="i==2" src="../../../assets/images/three.png" alt="">
                        </p>
                        <img class="bang-tou" v-lazy="l.thumb" alt="">
                        <span>{{l.uid}}/{{l.nickname}}</span>
                    </div>
                    <div class="bang-item-right">
                        <!-- 累计评论 -->
                        <span class="jifen">{{$t('mine.sum_comment')}}</span>
                        <span class="color-main">{{l.total_facebook}} {{$t('auction.no')}}</span>
                    </div>
                </div>
            </div>
        </div>
    </van-popup>
  </div>
</template>

<script>
const fields = [
    {label:"uid", prop:"unc"},
    {label:"rankname", prop:"rankname"},
    {label:"pvbalance", prop:"pvbalance"},
]
export default {
    data(){
        return {
            show:false,
            show1:false,
            show2:false,
            show3:false,
            title:"",
            alldata:{},
            list:[],
            mostBouns:{},
            mostFacebook:{},
            mostMoney:{},
            mostRank:{},
            formThead:[]
        }
    },
    name:'bang',
    watch:{
        mostRank(valArr){
            var _this = this;
            this.formThead = fields.map(function (value) {
                const arr = valArr.map(x => x[value.prop])  // 获取每一列的所有数据
                arr.push(value.label)  // 把每列的表头也加进去算
                value.width = _this.getMaxLength(arr) + 18 // 每列内容最大的宽度 + 表格的内间距(依据实际情况而定)
                return value
            })
            console.log(this.formThead)
        }
    },
    methods:{
        showPopup(title,key){
            this.show = true;
            this.title = title;
        },
        showPopup1(title,key){
            this.show1 = true;
            this.title = title;
        },
        showPopup2(title,key){
            this.show2 = true;
            this.title = title;
        },
        showPopup3(title,key){
            this.show3 = true;
            this.title = title;
        },
        /**
        * 遍历列的所有内容，获取最宽一列的宽度
        * @param arr
        */
        getMaxLength (arr) {
          return arr.reduce((acc, item) => {
            if (item) {
              let calcLen = this.getTextWidth(item)
              if (acc < calcLen) {
                acc = calcLen
              }
            }
            return acc
          }, 0)
        },
         /**
         * 使用span标签包裹内容，然后计算span的宽度 width： px
         * @param valArr
         */
        getTextWidth(str) {
          let width = 0;
          let html = document.createElement('span');
          html.innerText = str;
          html.className = 'getTextWidth';
          document.querySelector('body').appendChild(html);
          width = document.querySelector('.getTextWidth').offsetWidth;
          document.querySelector('.getTextWidth').remove();
          return width;
        }
    },
    mounted(){
        this.$ajax.honorlist()
        .then(res=>{
            if(res.code==200){
                this.alldata = res.data;
                this.mostBouns = res.data.mostBouns;
                this.mostFacebook = res.data.mostFacebook;
                this.mostMoney = res.data.mostMoney;
                this.mostRank = res.data.mostRank;

                
            }


        })
    }
}
</script>
